import React from "react"
import PropTypes from "prop-types"

import INavHeader from "./INavHeader"
import INavFooter from "./INavFooter"
import "./../styles/application.scss"

const Layout = ({ children }) => {
  return (
    <>
      <div id="top"></div>
      <INavHeader />
      <div className="site-wrapper">
        {children}
      </div>
      <INavFooter />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
